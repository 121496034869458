import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import { ApiProduct, Currency } from '../types';
import { axiosQuery } from '../utils/axiosQuery';

export interface ProductsResponse {
  products: ApiProduct[];
}

const GET_PRODUCT_QUERY = gql`
  query GetProducts($country: String!, $currency: String) {
    products(country: $country, currency: $currency) {
      name
      description
      pricing {
        currency
        amount
      }
      channel
      trial {
        length
        periodType
      }
      membership {
        periodType
        length
      }
      taxMode
    }
  }
`;

export const useGetProducts = (country: string, currency?: Currency) => {
  return useQuery<ProductsResponse>({
    queryKey: ['getProducts', country, currency],
    queryFn: async () => axiosQuery<ProductsResponse>('GetProducts', GET_PRODUCT_QUERY, { country, currency }),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!country,
  });
};
