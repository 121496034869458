import { useMutation } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import { type UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MessageKey } from 'translations';

import { validateAddress } from '../../graphql/validateAddress';
import { Field, Problem } from '../../types';
import { removeEmptyStringOrNilProperties } from '../../utils/helpers';

import { FormValues } from './types';

const ERROR_MESSAGE_TO_TRANSLATION_KEY_MAP: Record<string, MessageKey> = {
  'Incorrect Value: Municipality.': 'membership_addressform_error_incorrect_city',
  'Incorrect Value: Postal Code.': 'membership_addressform_error_incorrect_postalcode',
  'Incorrect Value: Street direction.': 'membership_addressform_error_incorrect_address',
  'Missing Value: Complete Street Information.': 'membership_addressform_error_general',
  'Missing Value: Municipality.': 'membership_addressform_error_missing_city',
  'Missing Value: Postal Code.': 'membership_addressform_error_missing_postalcode',
  'Missing Value: Street direction.': 'membership_addressform_error_missing_address',
  'Postal code not valid for the country.': 'membership_addressform_error_invalid_postalcode_for_country',
};

const FIELD_NAME_TO_TRANSLATION_KEY_MAP: Record<Field, MessageKey> = {
  city: 'membership_addressform_error_fieldname_city',
  country: 'membership_addressform_error_fieldname_country',
  postalCode: 'membership_addressform_error_fieldname_postalcode',
  state: 'membership_addressform_error_fieldname_state',
  address1: 'membership_addressform_error_fieldname_address',
  address2: 'membership_addressform_error_fieldname_address2',
};

export const useAddressTaxValidation = (formMethods: UseFormReturn<FormValues>) => {
  const { renderValidationFailures } = useHandleFieldErrors(formMethods);
  return useMutation({
    mutationKey: ['validateAddress'],
    mutationFn: validateAddress,
    throwOnError: true,
    onSuccess: ({ success, problems }) => {
      if (!success) {
        renderValidationFailures(problems);
      }
    },
  });
};

const useHandleFieldErrors = (formMethods: UseFormReturn<FormValues>) => {
  const intl = useIntl();
  const { setError: setFieldError, getValues } = formMethods;

  const isUnifiedAddress = getValues('isUnifiedAddress');
  const addressKey = isUnifiedAddress ? 'billingAddress' : 'shippingAddress';

  const renderValidationFailures = (problems: Problem[]): void => {
    // Set all field errors to corresponding input error label
    const fieldErrors = problems.filter((p) => !isNil(p.field));
    fieldErrors.forEach(({ field, message }) => {
      const localeKey: MessageKey = ERROR_MESSAGE_TO_TRANSLATION_KEY_MAP[message];
      const translatedMessage = localeKey
        ? intl.formatMessage({
            id: localeKey,
          })
        : undefined;
      const defaultMessage = intl.formatMessage({
        id: 'membership_hub_address_page_invalid_field',
      });

      setFieldError(`${addressKey}.${field!}`, {
        type: 'validate',
        message: translatedMessage ?? message ?? defaultMessage,
      });
    });

    // Generate banner error that lists all fields with errors
    const fieldNames = fieldErrors.map(({ field }) =>
      intl.formatMessage({ id: FIELD_NAME_TO_TRANSLATION_KEY_MAP[field!] }),
    );
    const addressFieldError =
      fieldNames.length > 0
        ? intl.formatMessage(
            {
              id: 'membership_hub_address_page_validation_error',
            },
            {
              fields: fieldNames.join(', '),
            },
          )
        : undefined;

    // Generate another banner for (first) error that does not have corresponding fields
    const firstNonFieldError = problems.find((p) => isNil(p.field === null) && p.message !== null);
    const generalErrorTranslationKey = ERROR_MESSAGE_TO_TRANSLATION_KEY_MAP[firstNonFieldError?.message ?? ''];
    const generalError = generalErrorTranslationKey
      ? intl.formatMessage({
          id: generalErrorTranslationKey,
        })
      : firstNonFieldError?.message;

    // Set banner error messages
    setFieldError('serverError', {
      // Ignore undefined errors, since they render empty string
      types: removeEmptyStringOrNilProperties({
        generalError,
        addressFieldError,
      }),
    });
  };

  return {
    renderValidationFailures,
  };
};
