import isEmpty from 'lodash/isEmpty';

import { ApiProduct, RatePlan, Subscription } from '../types';

import { throwError } from './errorHandler';

// sort by descending order (Yearly --> Monthly)
const sortByBillingPeriod = (a: RatePlan, b: RatePlan) => b.billingPeriod.localeCompare(a.billingPeriod);

export const convertProductToRatePlan = (product: ApiProduct): RatePlan => ({
  amount: product.pricing.amount,
  billingPeriod: product.membership.periodType,
  currency: product.pricing.currency,
});

export const getRatePlansFromProducts = (apiProducts: ApiProduct[]): RatePlan[] => {
  return apiProducts.map(convertProductToRatePlan).sort((a, b) => sortByBillingPeriod(a, b));
};

export const ratePlansFromSubscription = (currentSubscription: Subscription) => {
  const { recurringFee: currentPlan, alternativeBillingPeriods: altPlans } = currentSubscription;

  if (!currentPlan || isEmpty(altPlans)) {
    throwError('hubGeneralError', 'No rate plans found from current subscription');
  }

  const ratePlans = [currentPlan, ...altPlans!].toSorted(sortByBillingPeriod);
  return ratePlans;
};
